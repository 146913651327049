<template>
  <div class="first-order-benefits">
    <van-tabs
      v-model="active"
      @click="tabsClick"
      line-width="20"
      line-height="2"
    >
      <van-tab title="进行中" name="1" />
      <van-tab title="已结束" name="2" />
    </van-tabs>
    <div class="content-div">
      <template v-if="dataList.length > 0">
        <van-list
          v-model="loading"
          :finished="finished"
          finished-text="没有更多了"
          @load="onLoad"
        >
          <div
            :class="['content-li', active == 1 ? '' : 'content-li-2']"
            v-for="item in dataList"
            :key="item.couponId"
          >
            <div class="top-div">
              <p>
                <span class="name">{{ item.couponName }}</span>
                <span class="time"
                  >有效期至{{ item.endTime.split("T")[0] }}</span
                >
              </p>
              <p>
                <span class="worth"
                  >￥<font>{{ item.discountAmount }}</font></span
                >
                <span class="reduction">{{ item.useCondition }}</span>
              </p>
            </div>
            <div class="bottom-div">
              <span
                class="projectBtn"
                @click="
                  (projectContent = item.userServeName), (projectShow = true)
                "
                >适用项目 <van-icon name="arrow"
              /></span>
              <span class="status status_gary" v-if="active == 2">已结束</span>
              <!-- 1 待领取 2 已领取 -->
              <span
                class="status status_green"
                v-if="active == 1 && item.receiveStatus == 2"
                @click="receiveCoupon(item.couponId)"
                >待领取</span
              >
              <span class="status" v-if="active == 1 && item.receiveStatus == 1"
                >已领取</span
              >
            </div>
          </div>
        </van-list>
      </template>
      <template v-else>
        <div class="nullDataDiv">
          <img src="../../assets/img/error.png" alt="" />
          <span class="text">暂无数据</span>
        </div>
      </template>
    </div>
    <!-- 项目弹窗 -->
    <van-popup
      v-model="projectShow"
      position="bottom"
      :style="{ minHeight: '30%' }"
      round
    >
      <div class="projectPopup">
        <p class="title">适用项目</p>
        <p class="li">1、本优惠券可用于以下服务项目：{{ projectContent }}</p>
        <p class="li">2、本券一次只能使用一张</p>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { getLsCouponByTypePage, addMemberCoupon } from "@/api/index";
export default {
  data() {
    return {
      page: 1,
      total: 0,
      loading: false,
      finished: false,
      projectShow: false,
      active: 1,
      dataList: [],
      projectContent: ""
    };
  },
  created() {
    this.getLsCouponByTypePage();
  },
  methods: {
    // tab切换
    tabsClick() {
      this.page = 1;
      this.dataList = [];
      this.getLsCouponByTypePage();
    },
    // 点击领取优惠券
    receiveCoupon(id) {
      addMemberCoupon({ couponId: id }).then(res => {
        if (res.code == 10000) {
          this.$toast("领取成功！");
          this.page = 1;
          this.dataList = [];
          this.getLsCouponByTypePage();
        }
      });
    },
    // 获取列表数据
    getLsCouponByTypePage() {
      let data = {
        type: this.active,
        pageIndex: this.page,
        pageSize: 10
      };
      getLsCouponByTypePage(JSON.stringify(data)).then(res => {
        this.dataList = this.dataList.concat(res.data.records);
        this.total = res.data.total;
        this.loading = false;
        this.finished = false;
      });
    },
    // 分页
    onLoad() {
      if (this.dataList.length < this.total) {
        this.page++;
        this.getLsCouponByTypePage();
      } else {
        this.finished = true;
      }
    }
  }
};
</script>

<style lang="less" scoped>
.first-order-benefits {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  .van-tabs {
    position: sticky;
    top: 0;
    background: white;
    z-index: 10;
  }
  .content-div {
    flex: 1;
    display: flex;
    flex-direction: column;
    border-top: solid 1px #e3e3e3;
    margin-top: 5px;
    padding: 0.4rem;
    .content-li {
      background: rgb(255, 235, 235);
      padding: 0.3rem;
      border-radius: 0.3rem;
      &:not(:last-child) {
        margin-bottom: 0.2rem;
      }
      .top-div,
      .bottom-div {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .top-div {
        padding: 0.3rem;
        p {
          display: flex;
          flex-direction: column;
          &:last-child {
            white-space: nowrap;
          }
          .name {
            font-size: 0.36rem;
            font-weight: bold;
            margin-bottom: 0.3rem;
          }
          .time {
            color: gray;
          }
          .worth {
            color: red;
            & > font {
              font-size: 0.6rem;
              font-weight: bold;
            }
          }
        }
      }
      .bottom-div {
        padding: 0.3rem 0.3rem 0;
        border-top: dashed 1px rgb(255, 211, 211);
        .status {
          padding: 0.08rem 0.3rem;
          border: solid 1px red;
          color: red;
          border-radius: 0.4rem;
          font-size: 0.28rem;
        }
        .status_green {
          border-color: green;
          color: green;
        }
        .status_grey {
          border-color: grey;
          color: grey;
        }
        .projectBtn {
          font-size: 0.28rem;
          color: gray;
        }
      }
    }
    .content-li-2 {
      background: rgb(242, 242, 242);
      .top-div {
        p {
          .worth {
            color: #000;
          }
          .reduction {
            color: grey;
          }
        }
      }
      .bottom-div {
        border-top: dashed 1px rgb(192, 192, 192);
      }
    }
  }
  .projectPopup {
    padding: 0 0.4rem;
    .title {
      text-align: center;
      font-size: 0.5rem;
      font-weight: bold;
      padding: 0.4rem 0;
    }
    .li {
      margin-top: 10px;
    }
  }
}
</style>
